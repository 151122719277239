import { useState } from "react";
import { PlusIcon } from "../../img/icons";
import styles from "./Service.module.css";
export const Service = (data) => {
  const { title, list } = data;

  const [isOpen, setOpen] = useState(true);

  const toggleOpen = () => {
    setOpen(!isOpen);
  };
  return (
    <div className={styles.container}>
      <div className={styles.row} onClick={toggleOpen}>
        <p className={styles.serviceName}>{title}</p>

        <PlusIcon data-hidden={isOpen} />
      </div>

      <ul className={styles.list} data-hidden={!isOpen}>
        {list.map((item) => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </div>
  );
};
