import { AboutUs } from "./components/AboutUs";
import { Companies } from "./components/Companies";
import { FeedbackList } from "./components/FeedbackList";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Main } from "./components/Main";
import { Services } from "./components/Services";

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <Companies />
      <Services />
      <FeedbackList />
      <AboutUs />
      <Footer />
    </div>
  );
}

export default App;
