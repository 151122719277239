export const MailIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_106_1261)">
      <path
        d="M20.0079 4.5H4.00793C2.90793 4.5 2.01793 5.4 2.01793 6.5L2.00793 18.5C2.00793 19.6 2.90793 20.5 4.00793 20.5H20.0079C21.1079 20.5 22.0079 19.6 22.0079 18.5V6.5C22.0079 5.4 21.1079 4.5 20.0079 4.5ZM19.6079 8.75L12.5379 13.17C12.2179 13.37 11.7979 13.37 11.4779 13.17L4.40793 8.75C4.15793 8.59 4.00793 8.32 4.00793 8.03C4.00793 7.36 4.73793 6.96 5.30793 7.31L12.0079 11.5L18.7079 7.31C19.2779 6.96 20.0079 7.36 20.0079 8.03C20.0079 8.32 19.8579 8.59 19.6079 8.75Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_106_1261">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.00793457 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const PhoneIcon = () => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_106_1256)">
      <path
        d="M19.238 15.76L16.698 15.47C16.088 15.4 15.488 15.61 15.058 16.04L13.218 17.88C10.388 16.44 8.06798 14.13 6.62798 11.29L8.47798 9.44001C8.90798 9.01001 9.11798 8.41001 9.04798 7.80001L8.75798 5.28001C8.63798 4.27001 7.78798 3.51001 6.76798 3.51001H5.03798C3.90798 3.51001 2.96798 4.45001 3.03798 5.58001C3.56798 14.12 10.398 20.94 18.928 21.47C20.058 21.54 20.998 20.6 20.998 19.47V17.74C21.008 16.73 20.248 15.88 19.238 15.76Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_106_1256">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.00793457 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const PlusIcon = (data) => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...data}
  >
    <path
      d="M13 37.5H61"
      stroke="#303030"
      stroke-width="6"
      stroke-linecap="round"
    />
    <path
      d="M37 13.5V61.5"
      stroke="#303030"
      stroke-width="6"
      stroke-linecap="round"
    />
  </svg>
);

export const CircleIcon = () => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5" cy="5" r="5" fill="#D9D9D9" />
  </svg>
);
