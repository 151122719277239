import { SectionHeading } from "../SectionHeading";
import styles from "./Companies.module.css";
import commonStyles from "../Common/Common.module.css";

import { COMPANIES } from "../../constants";
import { Company } from "../Company";

export const Companies = () => {
  return (
    <section className={styles.container}>
      <div className={commonStyles.wrapper}>
        <SectionHeading>Компании в продаже</SectionHeading>
      </div>
      <div className={styles.carusel}>
        {COMPANIES.map((company) => (
          <Company key={company.inn} {...company} />
        ))}
      </div>
    </section>
  );
};
