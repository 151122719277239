import styles from "./Company.module.css";
export const Company = (data) => {
  const { inn, ifns, accounts, price } = data;

  return (
    <div className={styles.container}>
      <p className={styles.inn}>
        <span>ИНН: </span>
        {inn}
      </p>
      <p className={styles.ifns}>
        <span>Регистрация в ИФНС: </span>
        {ifns}
      </p>
      <p className={styles.accounts}>
        <span>Расчетные счета в банках: </span>
        {accounts}
      </p>
      <p className={styles.price}>{price}</p>
    </div>
  );
};
