import { SectionHeading } from "../SectionHeading";
import styles from "./FeedbackList.module.css";
import commonStyles from "../Common/Common.module.css";

import { FEEDBACK_LIST } from "../../constants";
import { Feedback } from "../Feedback";

export const FeedbackList = () => {
  return (
    <section className={styles.container}>
      <div className={commonStyles.wrapper}>
        <SectionHeading>Отзывы наших клиентов</SectionHeading>
      </div>
      <div className={styles.carusel}>
        {FEEDBACK_LIST.map((feedback) => (
          <Feedback key={feedback.name} {...feedback} />
        ))}
      </div>
    </section>
  );
};
