export const NUMBER_1 = "+7 (991)336-64-90";
export const MAIL_1 = "CGfinance@yandex.ru";
export const NUMBER_2 = "+7 (991)336-64-90";
export const MAIL_2 = "CGfinance@mail.ru";

export const whatsupLink = "https://wa.me/79000000000"; //https://wa.me/79000000000
export const telegramLink = "https://t.me/username"; //https://t.me/username
export const signalLink = "https://signal.me/username"; //https://signal.me/username

export const BREAK_POINTS = {
  xs: 0,
  sm: 500,
  md: 768,
  lg: 1100,
  xl: 1350,
  xxl: 1400,
};

// для секции "Компании в продаже"
// чтобы добавить новую компанию, необходимо скопировать обьект:
// const new_company = {
//   inn: "",
//   ifns: "",
//   accauns: "",
//   price: "Стоимость по запросу",
// };

//и вставить в этот массив снизу
export const COMPANIES = [
  {
    inn: "9721228600",
    ifns: "07.02.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
  },

  {
    inn: "9721228600",
    ifns: "07.02.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
  },

  {
    inn: "9721228600",
    ifns: "07.02.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
  },

  {
    inn: "9721228600",
    ifns: "07.02.2024",
    accounts: "нет",
    price: "Стоимость по запросу",
  },
];

export const SERVICES = [
  {
    title: "Юридическое и бухгалтерское сопровождение финансовых организаций",
    list: [
      "Единоразовая сдача Отчетов в ИФНС, фонды, Банк России, СРО для Микрокредитных компаний (МКК), Ломбардов и Кредитных потребительских кооперативов (КПК)",
      "Ведение бухгалтерского и юридического сопровождения деятельности МКК",
      "Ведение бухгалтерского и юридического сопровождения деятельности ломбардов",
      "Ведение бухгалтерского и юридического сопровождения деятельности КПК",
      "Разработка учетной политики для МКК, Ломбардов и КПК",
      "Ответы на запросы надзорных органов, представление интересов Клиента в надзорных органах и другие услуги по запросу Клиента для МКК, Ломбардов и КПК",
    ],
  },
  {
    title: "Услуги для участников микрофинансового рынка",
    list: [
      "Создание МКК, КПК и Ломбардов в любом регионе по запросу клиента, а также регистрация в ИФНС любых изменений юр. лица, а также создание нового юр. лица",
      "Помощь с поиском юр. адреса, подбором сотрудников, запуском офиса",
      "Обучение сотрудников в форме Целевого Инструктажа либо повышение квалификации по 115 –ФЗ",
      "Сопровождение сделок купли-продажи МКК и Ломбардов, помощь в переоформлении КПК",
      "Выпуск ЭЦП физ. лица",
      "Изготовление печатей",
      "Выезд курьера по Москве и МО",
    ],
  },
];

export const FEEDBACK_LIST = [
  {
    name: 'Заказчик "ООО "Финанс Консалт"',
    description:
      "Обращались в компанию ФинансКонсалт с целью покупки микрокредитной компании, купили вновь созданное юридическое лицо с полным пакетом документов, все оформили у нотариуса, результатом довольны в полной мере. Рекомендуем.",
  },
  {
    name: 'Заказчик "ООО "Финанс Консалт"',
    description:
      "Обращались в компанию ФинансКонсалт с целью покупки микрокредитной компании, купили вновь созданное юридическое лицо с полным пакетом документов, все оформили у нотариуса, результатом довольны в полной мере. Рекомендуем.",
  },
  {
    name: 'Заказчик "ООО "Финанс Консалт"',
    description:
      "Обращались в компанию ФинансКонсалт с целью покупки микрокредитной компании, купили вновь созданное юридическое лицо с полным пакетом документов, все оформили у нотариуса, результатом довольны в полной мере. Рекомендуем.",
  },
];

export const ABOUT_US_TITLE =
  "Ищете надежного партнера в сфере микрофинансирования?";

export const ABOUT_US =
  "Наша компания предлагает широкий спектр услуг, включая:\n\n- Продажу микрокредитных компаний: поможем вам выгодно приобрести МКК, соответствующую вашим целям и бюджету.\n\n- Продажу микрокредитных компаний: поможем вам выгодно приобрести МКК, соответствующую вашим целям и бюджету.\n\nСпектр микрофинансовых услуг: окажем комплексную поддержку в ведении вашего микрофинансового бизнеса, от разработки стратегии до сопровождения сделок.\n\nНаша команда опытных специалистов обладает глубокими знаниями рынка и всегда готова предоставить вам индивидуальный подход и качественный сервис.";
