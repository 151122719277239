import { Button } from "../Button";
import { Contacts } from "../Contacts";
import { SocialMedia } from "../SocialMedia";
import styles from "./Footer.module.css";
export const Footer = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <Button>Оставить заявку</Button>
      </div>

      <div className={styles.contacts}>
        <div className={styles.numbers}>
          <Contacts />
        </div>
        <SocialMedia />
      </div>
    </div>
  );
};
