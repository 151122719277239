import styles from "../Contacts/Contacts.module.css";
import { MAIL_1, MAIL_2, NUMBER_1, NUMBER_2 } from "../../constants";
import { MailIcon, PhoneIcon } from "../../img/icons";

export const Contacts = () => {
  return (
    <div className={styles.container}>
      <div className={styles.phone}>
        <a href={`tel:${NUMBER_1}`}>
          <PhoneIcon />
          {NUMBER_1}
        </a>

        <a href={`mailto:${MAIL_1}`}>
          <MailIcon />
          {MAIL_1}
        </a>
      </div>
      <div className={styles.mail}>
        <a href={`tel:${NUMBER_2}`}>
          <PhoneIcon />
          {NUMBER_2}
        </a>
        <a href={`mailto:${MAIL_2}`}>
          <MailIcon />

          {MAIL_2}
        </a>
      </div>
    </div>
  );
};
